<template>
  <div class="list">
    <div class="list__item list__col-name list__item--border-none">
      <div class="col--1">
        <p class="list__col-name">Lp.</p>
      </div>
      <div class="col--2">
        <p class="list__col-name">ID</p>
      </div>
      <div class="col--3">
        <p class="list__col-name">Imię i nazwisko</p>
      </div>
      <div class="col--1">
        <p class="list__col-name">ID teczki</p>
      </div>
      <div class="col--1">
        <p class="list__col-name">Wiek</p>
      </div>
      <div class="col--1">
        <p class="list__col-name">Akcje</p>
      </div>
    </div>
    <div class="list__item" v-for="(item, key) in items" :key="key">
      <div class="col--1">
        <p class="list__elem">{{ key + 1 }}.</p>
      </div>
      <div class="col--2">
        <p class="list__elem">{{ item.paradiso_id }}</p>
      </div>
      <div class="col--3">
        <p class="list__elem">{{ item.name }} {{ item.surname }}</p>
      </div>
      <div class="col--1">
        <p class="list__elem">{{ item.folder_id }}</p>
      </div>
      <div class="col--1">
        <p class="list__elem">{{ item.age }} lat</p>
      </div>
           <div class="col--1">
        <div class="list__icons">
          <div
            class="list__icon"
            @click="$emit('showMore', item)"
            v-tooltip="'Szczegóły'"
          >
            <img class="" src="@/assets/icons/more.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    items: Array,
  },
  // name: 'HelloWorld',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
</style>
