<template>
  <div class="p-participant">
    <div class="paginate">
      <div class="paginate__item"></div>
    </div>
    <div>
      <Input
        class="form__row--col-2"
        placeholder="Szukaj ID teczki"
        :value="test"
        @keyup.native.enter="
          searchAgreementsUsers({ field: 'folder_id', value: $event.target.value })
        "
      />
      <Input
        class="form__row--col-2"
        placeholder="Szukaj po: id, imię, nazwisko, PESEL, telefon, e-mail, imie opiekuna"
        :value="test"
        @keyup.native.enter="searchAgreementsUsers({ field: 'search', value: $event.target.value })"
      />
    </div>
    <!-- @return="searchEventUsers($event)" -->

    <List :items="agreementsUsers" @showMore="getDetails($event)" />

    <RightToolBar
      :showToolbar="details != null"
      v-on:closeToolbar="details = null"
    >
      <template v-slot:title>
        <div v-if="details">{{ details.name }} {{ details.surname }}</div>
      </template>
      <template v-slot:content>
        <div v-if="details">
          <div class="userDetails">
            <div>
              <p class="userDetails__title userDetails__title--first">
                Dane uczestnika
              </p>
              <p class="userDetails__item">ID: {{ details.paradiso_id }}</p>
              <p class="userDetails__item">PESEL: {{ details.pesel }}</p>
              <p class="userDetails__item">Telefon: {{ details.phone }}</p>
              <p class="userDetails__item">E-mail: {{ details.mail }}</p>
              <p class="userDetails__item" v-if="details.diet_id">
                Dieta: {{ registrationInfo.diets[details.diet_id] }}
              </p>
            </div>
            <div v-if="details.guardian_on_place">
              <p class="userDetails__title">Opiekun na miejscu</p>
              <p class="userDetails__item">
                Imię i nazwisko: {{ details.guardian_on_place.name }}
                {{ details.guardian_on_place.surname }}
              </p>
              <p class="userDetails__item">
                ID: {{ details.guardian_on_place.paradiso_id }}
              </p>
              <p class="userDetails__item">
                Telefon: {{ details.guardian_on_place.phone }}
              </p>
              <p class="userDetails__item">
                E-mail: {{ details.guardian_on_place.mail }}
              </p>
            </div>
            <div v-if="details.guardian_name">
              <p class="userDetails__title">Opiekun</p>
              <p class="userDetails__item">
                Imię i nazwisko: {{ details.guardian_name }}
              </p>
              <p class="userDetails__item">
                Telefon: {{ details.guardian_phone }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </RightToolBar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import List from "@/components/lists/Agreement-list.vue";
// import Paginate from "@/components/elements/Paginate.vue";
import Input from "@/components/elements/Input.vue";

export default {
  components: {
    List,
    // Paginate,
    Input,
  },
  data() {
    return {
      test: "",
      details: null,
      editDetails: null,
      userToDelete: null,
      dataToEditSave: {},
    };
  },
  computed: {
    ...mapGetters({
      agreementsUsers: "Agreements/getAgreementsUsers",
    }),
  },
  methods: {
    ...mapActions({
      fetchAgreementsUsers: "Agreements/fetchAgreementsUsers",

      searchAgreementsUsers: "Agreements/searchAgreementsUsers",
    }),
    getDetails(item) {
      this.details = item;
      this.details.guardian_on_place = this.eventUsers.data.find(
        (e) => e.id == item.guardian_id
      );
    },
    search(obj) {
        console.log(obj);
        this.searchAgreementsUsers
    }
  },
  mounted() {
    this.fetchAgreementsUsers();
  },
};
</script>
<style lang="scss" scoped>
.userDetails {
  &__item {
    margin-bottom: 1rem;
  }
  &__title {
    text-transform: uppercase;
    border-bottom: 1px solid $green;
    margin: 1.5rem -1rem;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    margin-top: 2.5rem;
    &--first {
      margin-top: 0;
    }
  }
}
</style>
